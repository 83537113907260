import { http } from '../http/http';
import { UserDetailsDTO, UserPost, UserRequestPost, UsersDTO } from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const userRepository = {
   getUser: async (idToken: string) => {
      const user = await http.post('http://localhost/login22', JSON.stringify({'token': idToken }) )
      return user
   },
   getUsers: async (search: string) : Promise<Users>=> {
      const users = await http.get<UsersDTO>(`${API_URL_BASE}/v1/manage-users${search}`);

      const { data, error, message } = users;

      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            idtipousuario: raw?.idtipousuario,
            nombre: raw?.nombre,
            email: raw?.email,
            password: raw?.password,
            is_active: raw?.is_active,
         })),
         error,
         message
      }
   },
   getUserDetails: async (id: number) : Promise<UserDetails>=> {
      const users = await http.get<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}`);

      let { data, error, message } = users;

      return {
         data,
         error,
         message
      }
   },
   createUser: async (dataUser: UserPost) : Promise<any> => {
      const userCreated = await http.post(`${API_URL_BASE}/v1/manage-users`, {
         idtipousuario: dataUser.idtipousuario,
         nombre: dataUser.nombre,
         email: dataUser.email,
         password: dataUser.password,
         is_active: true,
         roles: dataUser.roles,
      });

      return userCreated;
   },
   updateUser: async (id: number, dataUser: UserPost) : Promise<any> => {
      const userEdited = await http.patch<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}`, {
         idtipousuario: dataUser.idtipousuario,
         nombre: dataUser.nombre,
         email: dataUser.email,
         password: dataUser.password,
         roles: dataUser.roles,
      });

      return userEdited;
   },
   updateStatusUser: async (id: number, dataUser: UserPost) : Promise<any> => {
      const userEdited = await http.patch<UserDetailsDTO>(`${API_URL_BASE}/v1/manage-users/${id}`, {
         // is_active: dataUser.is_active
      });

      return userEdited;
   },
   deleteUser: async (id: number) : Promise<any> => {
      const userDeleted = await http.delete(`${API_URL_BASE}/v1/manage-users/${id}`)

      return userDeleted;
   },
   postVerifyUser: async (user_nick, rut_empresa) : Promise<any> => {
      const user = await http.get(`${API_URL_BASE}/v1/user/redirect/${user_nick}/${rut_empresa}`);

      return user;
   },
   postVerifyCompanies: async (rut_empresa) : Promise<any> => {
      const user = await http.get(`${API_URL_BASE}/v1/entity/verifyEntity?rut_empresa=${rut_empresa}`);

      return user;
   },
   updateChangePasswordByErp:async (id_user,data): Promise<any> => {
      const user = await http.post(`${API_URL_BASE}/v1/user/changePassword/${id_user}`,data );

      return user;
   },
}

import { configuracionRepository } from "../repositories/Configuracion.repository";

export const configuracionService = {
    getCorreoDefault: () => {
      return configuracionRepository.getCorreoDefault();
   },
   postCorreoDefault: (email, id) => {
      return configuracionRepository.postCorreoDefault(email, id);
   },

};

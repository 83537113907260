import { userRepository } from "../repositories/User.repository";

export const userService = {
   getUser: (idToken: string) => {
      return userRepository.getUser(idToken)
   },
   getUsers: (search= "") => {
      return userRepository.getUsers((search=="")? "/": `?search=${search}`)
   },
   getUserDetails: (id) => {
      return userRepository.getUserDetails(id)
   },
   createUser: (dataUser) => {
      return userRepository.createUser(dataUser)
   },
   updateUser: (id, dataUser) => {
      return userRepository.updateUser(id, dataUser)
   },
   updateStatusUser: (id, dataUser) => {
      return userRepository.updateStatusUser(id, dataUser)
   },
   deleteUser: (id) => {
      return userRepository.deleteUser(id)
   },
   postVerifyUser: (user_nick, rut_empresa) => {
      return userRepository.postVerifyUser(user_nick, rut_empresa)
   },
   postVerifyCompanies: ( rut_empresa) => {
      return userRepository.postVerifyCompanies( rut_empresa)
   },
   updateChangePasswordByErp:(id_user, data) => {
      return userRepository.updateChangePasswordByErp(id_user, data)
   }
};

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem, InputAdornment, ListItem, Chip,Autocomplete, IconButton, Tooltip, Snackbar, Alert, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, FormHelperText, Input } from '@mui/material';
import { Box, spacing } from '@mui/system';
import './Modal.sass'
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import moment from 'moment'
import { isTemplateExpression } from 'typescript';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFNET } from '@/toolbox/constants/local-storage';
import { mailService } from '@/service/services/Mail.service';

type ModalProps = {
  open: boolean,
  setOpen: any,
  data:any,
  setData:any,
  funcion?:any
}

export const ModalEnviarCopia: React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element => {

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'éxito',
        autoHideDuration: 3000,
    })

    const [error, setError] = useState<any>('')

    const handleEmail = (e) => {
        setError(false)
        props.setData({...props.data, correo:e.target.value})
    }

  const bodyModal = (
    <Box className='Modal' sx={{width:'20vw'}}>
      <Box >
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{color:'#3F9BCC',mt:1, mb:3, fontWeight:'700', fontSize:'1.4em'}}>
        Digite el correo a enviar copia
        </Typography>
        <Grid container spacing={2} justifyContent='center' px={3}>
            <TextField
            value={props.data.correo}
            size='small'
            fullWidth
            onChange={(e)=>handleEmail(e)}
            error={error=='invalido'?true:false}
            helperText={error=='invalido'?'El correo ingresado no es válido':''}
            /> 
            <Button
            variant='contained'
            sx={{ mt:2,
               background: '#E4980D', color: '#fff', '&:hover': {
                  bgcolor: '#E4980D'
               }
            }}
            onClick={()=>{
                const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (emailRegex.test(props.data?.correo)) {
                    props.funcion(props.data)
                    props.setOpen(false)
                } else {
                    setError('invalido')
                }
             }}>
                Enviar
            </Button>

        </Grid>
      </Box>
    </Box>
  )



  return (
    <div>
       <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false)

        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
}

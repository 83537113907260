// export const ROUTE_HOME            = '/';
export const ROUTE_HOME            = '/envios-pendientes';
export const ROUTE_ENVIADOS        = '/envios-realizados'
export const ROUTE_DASH            = '/dash'

// export const ROUTE_LOGIN           = '/login';
export const ROUTE_LOGIN           = '/';
export const ROUTE_REGISTER        = '/register';
export const ROUTE_SEETING         = '/configuraciones';
export const ROUTE_SEETING_REGISTER_PERIOD = "/registrar-periodo";
export const ROUTE_USERS           = '/usuarios';
export const ROUTE_USERS_REGISTER  = '/registrar-usuario';
export const ROUTE_USERS_UPDATE    = '/editar-usuario';
export const ROUTE_VIEWS_CREATE    = '/crear';
export const ROUTE_VIEWS_SUCESS    = '/success';
export const ROUTE_VIEWS_FORMAT    = '/formato';
export const ROUTE_VIEWS_PEER_USER = '/vistas-por-usuario';
export const ROUTE_VIEWS_PE_USA    = '/vistas-por-usuario/usuarios';
export const ROUTE_VIEWS_PE_EVA    = '/vistas-por-usuario/evaluacion';
export const ROUTE_VIEWS_TRACING     = '/seguimiento';
export const ROUTE_REPORTS = "/reportes";

import React, { useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Badge, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import { ROUTE_DASH, ROUTE_ENVIADOS, ROUTE_HOME} from '@/toolbox/constants/route-map';
import { DrawerComponent } from './Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import { readLocalStorage, removeLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BACK, KEY_SOFNET, KEY_TOOGLE_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { authenticationService } from '@/service/services/Authentication.service';
import { ModalEnviarCopia } from '@/views/CorreosEnviados/Modal/ModalEnviarCopia';
import SettingsIcon from '@mui/icons-material/Settings';
import { ModalConfiguracion } from '../Modal/ModalConfiguracion';

type Props = {
}

export const Header: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const history= useHistory();
   const [anchorEl, setAnchorEl] = React.useState(null);
   const isMenuOpen = Boolean(anchorEl);
   const [openModal, setOpenModal] = useState<boolean>(false);

   const [loading, setLoading] = useState<any>()

   const handleProfileMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleMenuClose = () => {
      setAnchorEl(null);
   };

   // const renderMenu = (
   //    <Menu
   //       id="fade-menu"
   //       MenuListProps={{
   //          'aria-labelledby': 'fade-button',
   //       }}
   //       anchorEl={anchorEl}
   //       open={isMenuOpen}
   //       onClose={handleMenuClose}
   //       TransitionComponent={Fade}
   //    >
   //        <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_DETAIL}>
   //        Boleta Procesada
   //       </MenuItem>
   //       <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_NOT_ISSUED}>
   //       Boleta no Emitida
   //       </MenuItem>
   //       <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_PROCESSED_CANCELED}>
   //       Boleta Anulada
   //       </MenuItem>
   //    </Menu>
   // );
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   const handleLogout = () => {
     removeLocalStorage(KEY_SOFNET);
     removeLocalStorage(KEY_BACK);
     removeLocalStorage(KEY_TOOGLE_MENU)
     removeLocalStorage(KEY_USER_DATA);
     localStorage.removeItem('dataUser');
     window.location.replace('/');
   }
   return (
      <div>
         <Box sx={{ flexGrow: 1, padding: 4 }}>
            <AppBar>
               <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', bgcolor:'#155172' }}>
                  <Grid>
                     <Button color="inherit" component={Link} to={ROUTE_HOME}>
                        <Typography
                           variant="h6"
                           noWrap
                           component="div"
                           sx={{ display: { xs: 'block', sm: 'block' } }}
                        >
                           ENVIO DE BOLETAS
                        </Typography>
                     </Button>
                     <IconButton sx={{mx:2, color:'#fff'}}  onClick={()=>{setOpenModal(true)}}>
                           <SettingsIcon/>
                       </IconButton>
                  </Grid>
                  {
                     isMobile ? (
                        <DrawerComponent />
                     ) : (
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} >
                           <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
                          
                              <Button color="inherit" sx={{bgcolor:'#3f9bcc',ml:1}} component={Link} to={ROUTE_HOME}>
                              PENDIENTES
                              </Button>
                              <Button color="inherit" sx={{bgcolor:'#3f9bcc',ml:1}} component={Link} to={ROUTE_ENVIADOS}>
                              ENVIADOS
                              </Button>
                              {/* <Button color="inherit" sx={{bgcolor:'#3f9bcc',ml:1}} component={Link} to={ROUTE_DASH}>
                              DASHBOARD
                              </Button> */}
                              {/* <Button color="inherit" sx={{bgcolor:'#1B5E20',ml:1}}
                                 onClick={handleProfileMenuOpen}>
                                 Boletas
                              </Button> */}
                              <Button color="inherit" sx={{bgcolor:'#E4980D',ml:1}} onClick={()=>handleLogout()}>
                                Cerrar Sesion
                              </Button>
                           </Box>
                        </Box>
                     )
                  }
                   {/* {renderMenu} */}
               </Toolbar>
            </AppBar>
            {openModal && 
            <ModalConfiguracion
            open={openModal}
            setOpen={setOpenModal}
            />}
         </Box>
      </div>
   );
}

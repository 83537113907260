import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { MenuIcon, PencilIcon } from "@toolbox/constants/icons";
import { Button, List,Divider,Drawer,ListItem, ListItemButton, Grid, MenuItem, } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import {ROUTE_DASH, ROUTE_HOME } from '@/toolbox/constants/route-map';
import { authenticationService } from '@/service/services/Authentication.service';
import { removeLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_BACK, KEY_SOFNET, KEY_TOOGLE_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';


type Props = {
}
export const DrawerComponent: React.FC<Props> = (
    props: Props
): JSX.Element => {

    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const [loading, setLoading] = React.useState<any>()

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
      };

//    const renderMenu = (
//       <Menu
//          id="fade-menu"
//          MenuListProps={{
//             'aria-labelledby': 'fade-button',
//          }}
//          anchorEl={anchorEl}
//          open={isMenuOpen}
//          onClose={handleMenuClose}
//          TransitionComponent={Fade}
//       >
//          <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_DETAIL}>
//             Boleta Procesada
//          </MenuItem>
//          <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_NOT_ISSUED}>
//          Boleta no Emitida
//          </MenuItem>
//          <MenuItem onClick={handleMenuClose} color="inherit" component={Link} to={ROUTE_GENERATOR_PROCESSED_CANCELED}>
//          Boleta Anulada
//          </MenuItem>
//       </Menu>
//    );

   const handleLogout = () => {
      removeLocalStorage(KEY_SOFNET);
      removeLocalStorage(KEY_BACK)
      removeLocalStorage(KEY_TOOGLE_MENU)
      removeLocalStorage(KEY_USER_DATA);
      localStorage.removeItem('dataUser');
      window.location.replace('/');
    }

    return (
        <Box >
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <List>
                    <ListItem >
                        <IconButton onClick={() => setOpenDrawer(!openDrawer)} >
                            <MenuIcon fill="black"/>
                        </IconButton>
                    </ListItem>
                    <Divider />
                    <ListItem onClick={() => setOpenDrawer(false)}>
                    <Button color="inherit" component={Link} to={ROUTE_DASH}>
                              Dashboard
                              </Button>
                    </ListItem>
                  <ListItem onClick={()=>handleLogout()}>
                        <Button color="error">
                           salir
                        </Button>
                    </ListItem>
                </List>
            </Drawer>
            <Grid>
                <IconButton onClick={() => setOpenDrawer(!openDrawer)} >
                    <MenuIcon fill="white"/>
                </IconButton>
            </Grid>
        </Box>
    );
}


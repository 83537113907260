import { mailRepository } from "../repositories/Mail.repository";

export const mailService = {
   sendMail: (data) => {
      return mailRepository.sendMail(data);
   },
   searchMailEnviados: (value) =>{
      return mailRepository.searchMailEnviados(value);
   },
   searchMailPendientes: (value) =>{
      return mailRepository.searchMailPendientes(value);
   },
   sendMailCoincidentes: (data, dataCoincidente) => {
      return mailRepository.sendMailCoincidentes(data, dataCoincidente);
   },
   sendFeedbackOpenning: (periodId:number) =>{
      return mailRepository.sendFeedbackNotifyOpening(periodId);
   },
   sendFeedbackClosure: (memberFeedbackId: number) => {
      return mailRepository.sendFeedbackNotifyClosure(memberFeedbackId);
   },
   libroVentasDetallePorFecha: (token, data) => {
      return mailRepository.libroVentasDetallePorFecha(token, data);
   },
   libroVentasDetalle: (token, data) => {
      return mailRepository.libroVentasDetalle(token, data)
   },
   relacionClienteCorreo: (rut_cliente, correo) => {
      return mailRepository.relacionClienteCorreo(rut_cliente, correo);
   },
   getRelacionClienteCorreo: () => {
      return mailRepository.getRelacionClienteCorreo();
   },
   getBoletasEnviadas: (user_id) => {
      return mailRepository.getBoletasEnviadas(user_id);
   },
   getBoletasPendientes: () => {
      return mailRepository.getBoletasPendientes();
   },
   postReenviarCorreo: (boleta, user_id) => {
      return mailRepository.postReenviarCorreo(boleta, user_id);
   }
};

import { http } from '../http/http';
import { API_URL_BASE } from '@/toolbox/defaults/app';

export const configuracionRepository = {
   postCorreoDefault: async (email, id) => {
      const resp = await http.post(`${API_URL_BASE}/v1/configuracion/email`,{
         email: email,
         id:id
      })
      return resp;
   },
   getCorreoDefault: async () => {
      const resp = await http.get(`${API_URL_BASE}/v1/configuracion/email`)
      return resp;
   },
   
}


import { http } from '../http/http';
import { API_URL_BASE } from '@/toolbox/defaults/app';

export const mailRepository = {
   sendMail: async (data) => {
      const resp = await http.post(`${API_URL_BASE}/v1/boletas/enviar`,{
         boleta: data
      })
      return resp;
   },
   searchMailEnviados: async (value) => {
      const resp = await http.post(`${API_URL_BASE}/v1/boletas/enviados/busqueda/${value}`,{})
      return resp;
   },
   searchMailPendientes: async (value) => {
      const resp = await http.post(`${API_URL_BASE}/v1/boletas/pendientes/busqueda/${value}`,{})
      return resp;
   },
   sendMailCoincidentes: async (data, dataCoincidente) => {
      const resp = await http.post(`${API_URL_BASE}/v1/boletas/enviar-coincidentes`,{
         boleta: data,
         boletas: dataCoincidente
      })
      return resp;
   },
   sendFeedbackNotifyOpening: async (periodId: number) => {
      const opening = await http.post(`${API_URL_BASE}/v1/mail/feedback/${periodId}/opening`)
   },
   sendFeedbackNotifyClosure: async (feedbackMmeberId: number) => {
      const closure = await http.post(`${API_URL_BASE}/v1/mail/feedback/${feedbackMmeberId}/closure`)
   },
   relacionClienteCorreo: async (rut_cliente, correo) => {
      const resp = await http.post(`${API_URL_BASE}/v1/cliente/rut/${rut_cliente}?correo=${correo}`)
      return resp;
   },
   getRelacionClienteCorreo: async () => {
      const resp = await http.get(`${API_URL_BASE}/v1/cliente/getCorreos`)
      return resp;
   },
   getBoletasEnviadas: async (user_id) => {
      const resp = await http.get(`${API_URL_BASE}/v1/boletas/enviados?id_usuario=${user_id}`)
      return resp;
   },
   getBoletasPendientes: async () => {
      const resp = await http.get(`${API_URL_BASE}/v1/boletas/pendientes`)
      return resp;
   },
   postReenviarCorreo: async (boleta, user_id) => {
      const resp = await http.post(`${API_URL_BASE}/v1/boletas/reenviar`,{
         boleta: boleta,
         id_usuario: user_id
      })
      return resp;
   },
   libroVentasDetallePorFecha,
   libroVentasDetalle
}

function libroVentasDetallePorFecha(token, data){
   return fetch('http://api.softnet.cl/libroVentasDetallePorFecha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token,
      },
      body: JSON.stringify(data),
    })
      .then(pros => pros.json())
      .then(async pros => {
        return pros
      })
}
function libroVentasDetalle(token, data){
   return fetch('http://api.softnet.cl/libroVentasDetalle',{
      method:'POST',
      headers: {
         'Content-Type': 'application/x-www-form-urlencoded',
         token: token,
      },
      body: JSON.stringify(data),
   })
    .then(pros => pros.json())
    .then(async pros =>{
       return pros
    })
}

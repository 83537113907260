import React, { useEffect, useRef, useState } from 'react';
import * as qs from 'query-string';
// //import ZendyTitle from 'assets/images/ZendyTitle.png';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { VisibilityIcon, VisibilityOffIcon } from '@/toolbox/constants/icons';
import { userService } from '@/service/services/User.service';
import { authenticationService } from '@/service/services/Authentication.service';
import { ROUTE_HOME } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom';
//import { pColor, sColor } from 'assets/styles/zendy-css';
//import { getSessionInfo } from "utils/common";

export const RegisterView: React.FC<any> = ( props ): JSX.Element => {

    const history = useHistory();
    const { rut_empresa = '', usuario = "", password = "" } = props.location && qs.parse(props.location.search);
    const [ChangePassExit, setChangePassExit] = React.useState(true);
    const [timeInterval, setTimeInterval] = React.useState(9)
    const [loading, setLoading] = React.useState(false);
    const [loadingSavePassword, setLoadingSavePassword] = React.useState(false);
    const [loadingCompany, setLoadingCompany] = React.useState(false);
    const [Redireccion, setRedireccion] = React.useState(6);//6 por defecto
    const [tokenNotify, setTokenNotify] = React.useState();
    const [dataUser, setDataUser] = React.useState();
    const [dataEntity, setDataEntity] = React.useState();
    const [statusPassword, setStatusPassword] = React.useState(false);
    const [solicitudCompany, setSolicitudCompany] = React.useState(0);

    const [showPasswordActual, setShowPasswordActual] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState("");
    const [errors, setErrors] = React.useState({});

    const onChangeOldPassword = (e) => {
        const oldPassword = e.target.value;
        setOldPassword(oldPassword);
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        //Password
        if (!oldPassword) {
            formIsValid = false;
            errors["oldPassword"] = "Contraseña requerida";
        }
        setErrors(errors);
        return formIsValid;
    }

    const CompararAndEnviarPassword = () => {
        if (handleValidation()) {
            setLoadingSavePassword(true)
            // setTimeout(() => {
            ChangePasswordByErp(dataUser)
            //  }, 1000);
        }
    }

    const RequestRegisterNewCompanies = async (data) => {
        // const res = await companyService.RequestRegisterNewCompanies(data);
        // return res && res.data || [];
    }

    const RegistrerCompany = async () => {
        console.log(solicitudCompany)
        if (solicitudCompany == 1) {
            let decodeRutEmpresa = atob(rut_empresa);
            const body = {
                ruc: decodeRutEmpresa,
            };
            const res = await RequestRegisterNewCompanies(body)
            //   if(res?.status)
            //   {
            //     setRedireccion(4)
            //   }else
            //   {
            //    // props.dispatch(showSnackBar("success", "Su empresa ya solicitó acceso" || ""));
            //   }
        }
        if (solicitudCompany == 2) {
            let decodeRutEmpresa = atob(rut_empresa);
            let decodeUser = atob(usuario);

            const body = {
                ruc: decodeRutEmpresa,
                username: decodeUser,
            };
            const res = await RequestRegisterNewCompanies(body)
            //   if(res.status)
            //   {
            //     setRedireccion(4)
            //   }else
            //   {
            //     //props.dispatch(showSnackBar("success", "ya has registrado tu solicitud anteriormente" || ""));
            //   }
        }

    }

    const [progress, setProgress] = React.useState(10);
    const session = 'sdhfjshdfhs'//localstorage

    React.useEffect(() => {
        VerifiCompanies()
    }, []);

    const VerifiCompanies = async () => {
        if (Redireccion == 6) {
            setSolicitudCompany(1)
            // setTimeout(() => {
            if (rut_empresa) {
                const res:any = await userService.postVerifyCompanies(atob(rut_empresa))
                console.log(res)
                if (res.data.status==true) {//true
                  setDataEntity(res.empresa)
                  VerifiUser()
                }else{
                  setRedireccion(3)
                }
            }
        //  }, 1000);
        }

    }

    const ChangePasswordByErp = async (user) => {
        const body = {
            password: 'boleta2022',
            newPassword: oldPassword,
            encryptPassword: atob(password)
        }
        console.log(body);
      if(body.newPassword && body.encryptPassword)
      {
         console.log(body);
         const res:any = await userService.updateChangePasswordByErp(user.id, body)
         if(res.data.estado==false)
         {
           setLoadingSavePassword(false)
           setStatusPassword(true);
           //props.dispatch(showSnackBar('error', 'La contraseña ingresada no es igual que ERP'));
         }
         else if(res.data.estado==true)
         {
           console.log('TRUEEEE')
           setRedireccion(2)
           changeAutoLogin()
         }
      }
        //props.dispatch(showSnackBar('success', 'Cambio de contraseña exitosa.'));
    }

      const VerifiUser = async () => {
        console.log('entre')
        setSolicitudCompany(2)
        const res = await userService.postVerifyUser(atob(usuario), atob(rut_empresa))
            setDataUser(res.data.user)
            if(res.data.message=='Contraseña no cambiada')
            {
            //  setRedireccion(1)
             setTimeout(() => {
              setRedireccion(7)
              ChangePasswordByErp(res.data.user)
            }, 1000);

            }
            else if(res.data.message=='Contraseña cambiada')
            {
              setRedireccion(5)
              // setTimeout(() => {
                if (usuario && password) {
                  console.log('entree')
                  var decodeRutEmpresa;
                  var decodeUser;
                  var decodePassword;
                  try {
                    decodeRutEmpresa = atob(rut_empresa);
                    decodeUser = atob(usuario);
                    decodePassword = atob(password);

                    const body = {
                      rut: decodeRutEmpresa,
                      username: decodeUser,
                      password: decodePassword
                    };
                    const resp = await authenticationService.login(decodeUser,decodeRutEmpresa, decodePassword, 'erp')
                        //valido
                        console.log(resp)
                        history.push(ROUTE_HOME);

                  } catch (error) {
                    console.log('hay error');
                    history.push(ROUTE_HOME);
                    setLoading(false);
                  }
                }
                else {
                //   if (session && session.token) {
                //     history.push("/inicio",1);
                //   } else {
                //     history.push("/");
                //   }
                }
              // }, 1000);

            }
            else
            {
              //redireeccion
              setRedireccion(3)
            }
      }

    const changeAutoLogin = async () => {
        if (Redireccion == 2) {
            console.log('entre')
            setLoading(true);
            // setTimeout(() => {

            if (usuario && password) {
                console.log('entree')
                var decodeRutEmpresa;
                var decodeUser;
                var decodeFecha;
                var decodePassword;
                try {
                    decodeRutEmpresa = atob(rut_empresa);
                    decodeUser = atob(usuario);
                    decodePassword = atob(password);

                    const body = {
                        rut: decodeRutEmpresa,
                        username: decodeUser,
                        password: decodePassword
                    };
                    const resp = await authenticationService.login(decodeUser,decodeRutEmpresa, decodePassword, 'erp')
                    //valido
                    history.push(ROUTE_HOME);

                } catch (error) {
                    console.log('hay error');
                    history.push(ROUTE_HOME);
                    setLoading(false);
                }

            }
            else {
                // if (session && session.token) {
                 //    history.push();
                // } else {
                //     history.push("/");
                // }
            }
            // setLoading(false);
            // }, 1000);
        }
    }

    useEffect(()=>{
        if(Redireccion == 2){
                document.getElementById('btn-autologin').click();
           // console.log(button)
        }

    },[Redireccion])

    const EstadoView = (estado) => {
        switch (estado) {
            case 1:
                return (
                    <div className='formulario'
                        style={{
                            padding: '35px',
                            borderRadius: '15px',
                            background: '#fff',
                            width: '40%',
                            border: '0px',
                            boxShadow: 'rgb(0 0 0 / 30%) 6px 6px 6px 6px',
                        }}>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            Su cuenta Softnet Envios de Correos se está creando por favor espere...
                        </p>
                        <div className='formulario-imagen'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '30px',
                            }}>
                            {/* <img src={ZendyTitle} width={'300px'} height={'150px'} /> */}
                        </div>
                        {/* <LinearProgress /> */}
                    </div>
                )
            case 2:
                return (
                    <div className='formulario'
                        style={{
                            padding: '35px',
                            borderRadius: '15px',
                            background: '#fff',
                            width: '40%',
                            border: '0px',
                            boxShadow: 'rgb(0 0 0 / 30%) 6px 6px 6px 6px',
                        }}>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            Gracias por esperar, tu registro fue exitoso, podrás acceder a Softnet Envios de Correos con tus credenciales de Erp
                        </p>
                        <div className='formulario-imagen'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '30px',
                            }}>
                            {/* <img src={ZendyTitle} width={'300px'} height={'150px'} /> */}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                id='btn-autologin'
                                style={{
                                    width: '50%',
                                    padding: '15px',
                                    borderRadius: '8px',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}
                                disabled={loading}
                                onClick={() => { changeAutoLogin() }}
                            >
                                <span>Realizando login automatico espere porfavor</span>
                                <span
                                    style={{
                                        width: '20%',
                                        marginLeft: '4px',
                                        padding: '6px 5px',
                                        borderRadius: '100%',
                                        border: 'none',
                                        cursor: 'pointer',
                                        color: '#fff'
                                    }}>
                                    {/* {loading &&
                                    <CircularProgress
                                        size={24}
                                        color={'secondary'}
                                        sx={{
                                            position: 'absolute',
                                            top: '10%',
                                            left: '10%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />} */}
                                </span>
                            </button>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div className='formulario'
                        style={{
                            padding: '35px',
                            borderRadius: '15px',
                            background: '#fff',
                            width: '40%',
                            border: '0px',
                            boxShadow: 'rgb(0 0 0 / 30%) 6px 6px 6px 6px',
                        }}>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            {/* {solicitudCompany == 1 ? 'Su compañia no esta registrada, por favor Envianos una solicitud para que sea parte de Zendy' :
                            `Su compañia ${dataEntity.name.toLowerCase()} es parte de zendy pero su Usuario no está registrado, por favor envianos una solicitud para que sea parte de Zendy`} */}
                        </p>
                        <div className='formulario-imagen'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '30px',
                            }}>
                            {/* <img src={ZendyTitle} width={'300px'} height={'150px'} /> */}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <p
                                className='formulario-title'
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    fontFamily: 'sans-serif',
                                    //textAlign: 'center'
                                }}>
                                click Aqui

                            </p>
                            <div
                                style={{
                                    marginLeft: '10px',
                                    marginTop: '22px'
                                }}
                            >
                                {/* <ArrowDownwardIcon /> */}
                            </div>

                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                style={{
                                    width: '50%',
                                    padding: '15px',
                                    borderRadius: '8px',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    // background:'#DD843A'
                                }}
                                onClick={() => { RegistrerCompany() }}
                            >
                                <span >{loadingCompany ? `Procesando su Solicitud Espere un Momento` : `Enviar solicitud de registro`}</span>
                                <span
                                    style={{
                                        width: '20%',
                                        marginLeft: '4px',
                                        padding: '6px 5px',
                                        borderRadius: '100%',
                                        border: 'none',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }}>
                                    {/* {loadingCompany &&
                                    <CircularProgress
                                        size={24}
                                        color={'secondary'}
                                        sx={{
                                            position: 'absolute',
                                            top: '10%',
                                            left: '10%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />} */}
                                </span>
                            </button>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className='formulario'
                        style={{
                            padding: '35px',
                            borderRadius: '15px',
                            background: '#fff',
                            width: '40%',
                            border: '0px',
                            boxShadow: 'rgb(0 0 0 / 30%) 6px 6px 6px 6px',
                        }}>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            Su solicitud ha sido enviada, se lo atenderá lo mas pronto posible
                        </p>
                        <div className='formulario-imagen'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '30px',
                            }}>
                            {/* <img src={ZendyTitle} width={'300px'} height={'150px'} /> */}
                        </div>
                        {/* <LinearProgress /> */}
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 700,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            ¡Muchas gracias!
                        </p>
                    </div>
                )
            case 5:
                return (
                    <div className='formulario'
                        style={{
                            padding: '35px',
                            borderRadius: '15px',
                            background: '#fff',
                            width: '40%',
                            border: '0px',
                            boxShadow: 'rgb(0 0 0 / 30%) 6px 6px 6px 6px',
                        }}>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            Bienvenido Nuevamente a Softnet Envio de Correos, Estamos Listos para ayudarlo

                        </p>
                        <div className='formulario-imagen'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '30px',
                            }}>
                            {/* <img src={ZendyTitle} width={'300px'} height={'150px'} /> */}
                        </div>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            Iniciando Sesion...
                        </p>
                        {/* <LinearProgress /> */}
                    </div>
                )
            case 6:
                return (
                    <div className='formulario'
                        style={{
                            padding: '35px',
                            borderRadius: '15px',
                            background: '#fff',
                            width: '40%',
                            border: '0px',
                            boxShadow: 'rgb(0 0 0 / 30%) 6px 6px 6px 6px',
                        }}>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            Bienvenido a Softnet Envio de Correos
                        </p>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            Softnet Envio de Correos te ayuda a gestionar tus boletas emitidas, enviandolas por correos.
                        </p>
                        <div className='formulario-imagen'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '30px',
                            }}>
                            {/* <img src={ZendyTitle} width={'300px'} height={'150px'} /> */}
                        </div>
                        {/* <LinearProgress /> */}
                    </div>
                )
            case 7:
                return (
                    <div className='formulario'
                        style={{
                            padding: '35px',
                            borderRadius: '15px',
                            background: '#fff',
                            width: '40%',
                            border: '0px',
                            boxShadow: 'rgb(0 0 0 / 30%) 6px 6px 6px 6px',
                        }}>
                        <p
                            className='formulario-title'
                            style={{
                                paddingBottom: '30px',
                                fontWeight: 600,
                                textAlign: 'center',
                                fontSize: '20px',
                                fontFamily: 'sans-serif'
                            }}>
                            {statusPassword ? 'No coincide con la contraseña de ERP porfavor vuelva a ingresar' : 'Ingrese su contraseña de ERP'}

                        </p>
                        <TextField
                            placeholder='Contraseña de ERP'
                            // name={value.name}
                            type={showPasswordActual ? "text" : "password"}
                            onChange={onChangeOldPassword}
                            value={oldPassword}
                            style={{
                                width: '100%',
                                padding: '10px',
                                boxShadow: 'rgb(0 0 0 / 4%) 5px 1px 3px',
                                borderRadius: '10px' //opcional
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => { setShowPasswordActual(!showPasswordActual) }}
                                            edge="end"
                                            style={{ color: '#fff' }}
                                        >
                                            {showPasswordActual ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <span style={{
                            color: "red", display: 'flex', flexDirection: 'column',
                            alignItems: 'center'
                        }}>{errors["oldPassword"]}</span>

                        <div className='formulario-imagen'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: '30px',
                            }}>
                            {/* <img src={ZendyTitle} width={'300px'} height={'150px'} /> */}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <button
                                style={{
                                    width: '50%',
                                    padding: '15px',
                                    borderRadius: '8px',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}
                                disabled={loadingSavePassword}

                                onClick={() => CompararAndEnviarPassword()}
                            >
                                <span>Continuar creando su Perfil </span>
                                <span
                                    style={{
                                        width: '20%',
                                        marginLeft: '4px',
                                        padding: '6px 5px',
                                        borderRadius: '100%',
                                        border: 'none',
                                        cursor: 'pointer',
                                        color: '#fff'
                                    }}>
                                    {/* {loadingSavePassword &&
                                    <CircularProgress
                                        size={24}
                                        color={'secondary'}
                                        sx={{
                                            position: 'absolute',
                                            top: '10%',
                                            left: '10%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />} */}
                                </span>
                            </button>
                        </div>
                    </div>
                )
        }
    }
        return (
            <div id='section-formulario'
                style={{
                    height: '100vh',
                    display: 'flex',
                    background: '#fff',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>

                {EstadoView(Redireccion)}
            </div>
        )
    };

import { http } from '../http/http';
import { LoginDTO, LogoutDTO, UserDTO } from '../http/dto/AuthenticationDTO';
// import { LoginDTO, LogoutDTO } from '../http/dto/AuthenticationDTO';
import { Login, Logout, Authentication } from '../models/Authentication';
import { API_URL_BASE } from '@/toolbox/defaults/app';

export const authenticationRepository = {
   login: async (user_nick,rut_empresa, password, from): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/v1/auth/login`, {
         user_nick: user_nick,
         rut_empresa: rut_empresa,
         password:password,
         from:from
      })
      return {
         error: resp.error,
         status: resp.status,
         message: resp.message,
         data: !!resp.data ? {
               token_softnet: resp.data?.token_softnet,
               token: resp.data?.access_token || '',
               expires_in: resp.data?.expires_in || 0,
               token_type: resp.data?.token_type || ''
         } : undefined
      }
   },
   logout: async (): Promise<Logout>=> {
      const resp = await http.post<LogoutDTO>(`${API_URL_BASE}/v1/auth/sign-out`)
      return {
         error: resp?.error,
         status: resp?.status,
         message: resp?.message
      }
   },
   profile: async (access_token: string): Promise<Authentication>=> {
      const resp = await http.get<any>(`${API_URL_BASE}/v1/auth/me`)
      return {
         user: {
            id: resp.data?.idusuario,
            email: resp.data?.email,
            nombre: resp.data?.nombre,
            idtipousuario: resp.data?.idtipousuario,
            role: resp.data?.role,
            main_redirect: '/',
         },
         error: resp.error,
         token: access_token
      }
   },
}

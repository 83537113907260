import React, { useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, RouteProps, Switch, Redirect } from 'react-router-dom';
import * as Routes from '@constants/route-map';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@toolbox/constants/local-storage';
import {
	ROLE_ADMIN,
	ROLE_SUPER_ADMIN,
	ROLE_USER,
	ROLE_ADMIN_WITH_USER} from '@/toolbox/defaults/static-roles';
import Unauthorized from '@views/_unautorized';
import { Login } from "@views/Login"
import { HomeView } from "@views/Home"
import { SeetingView } from '@views/Seeting';
import { PrivateRoute, AuthRoute } from './PrivateRoute';
import { RegisterView } from '@/views/Register/Register';
import { CorreosEnviadosView } from '@/views/CorreosEnviados';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';

const AllRoutes: React.FC = () => {

	const dataUser= readLocalStorage(KEY_USER_DATA);
	const moduleHome = [
		<PrivateRoute key={15} exact path={Routes.ROUTE_HOME} component={HomeView} />
	];
	const moduleCorreosEnviados = [
		<PrivateRoute key={15} exact path={Routes.ROUTE_ENVIADOS} component={CorreosEnviadosView} />
	];
	const moduleReport = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_HOME}
         component={HomeView}
      />,
    ];

	const routes = useMemo(() => {
		let role: string = '';
		console.log(dataUser)
		if(!!dataUser) {
			if (!!dataUser.user) {
				role = dataUser.user.role;
				console.log(role)
			}

		}

		switch (role) {
			case ROLE_SUPER_ADMIN :
				return (
					<Router>
						<Switch>
						<AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
						{/* {moduleViewsPerUser}
						{moduleSeeting}
						{moduleReport} */}
						{/* {moduleUser} */}
						{ <Route path="*" exact={true} component={() => {
                     return <Redirect to={Routes.ROUTE_HOME}/>
						}} />}
						</Switch>
					</Router>
				);
			case ROLE_ADMIN :
				return (
					<Router>
						<Switch>
							<AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
							{/* {moduleSeeting} */}
							{/* {moduleUser} */}
							{moduleHome}
							{moduleCorreosEnviados}
							{<Route path='*' exact={true} component={() => {
								return <Redirect to={Routes.ROUTE_HOME}/>
							}} />}
						</Switch>
					</Router>
				);
			case ROLE_USER :
				return (
					<Router>
						<Switch>
							<AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
							{/* {moduleViewsPerUser} */}
							{<Route path='*' exact={true} component={() => {
								return <Redirect to={Routes.ROUTE_VIEWS_PEER_USER}/>
							}} />}
						</Switch>
					</Router>
				);
			case ROLE_ADMIN_WITH_USER :
				return (
					<Router>
						<Switch>
							<AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
							{/* {moduleSeeting} */}
							{/* {moduleReport} */}
							{/* {moduleUser} */}
							{/* {moduleViewsPerUser} */}
							{<Route path='*' exact={true} component={() => {
								return <Redirect to={Routes.ROUTE_HOME}/>
							}} />}
						</Switch>
					</Router>
				);
			default:
				return (
					<Router>
						<Switch>
							<Route exact path={Routes.ROUTE_HOME} component={HomeView} />
							<Route exact path={Routes.ROUTE_REGISTER} component={RegisterView} />
							{moduleCorreosEnviados}
							<AuthRoute exact path={Routes.ROUTE_LOGIN} component={Login} />
							{<Route path='*' exact={true} component={() => {
								return <Redirect to={Routes.ROUTE_LOGIN}/>
							}} />}
						</Switch>
					</Router>
				)
		}
	},// eslint-disable-next-line
	[JSON.stringify(dataUser)]);

	return routes;
}

export default AllRoutes;

import React, { useEffect, useRef, useState } from 'react';

import { Protected } from '@components/layout/Protected';
import { Props } from './Home.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Button, CircularProgress, Container,InputLabel , Divider, FormControl, FormHelperText, Grid, Input, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { SpinnerGrow } from '@/components/common/Spinner';
import Paper from '@mui/material/Paper';
import { mailService } from '@/service/services/Mail.service';
import { set } from 'react-hook-form';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import moment from 'moment'
import { isTemplateExpression } from 'typescript';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ModalClienteSinCorreo } from './Modal/ModalClienteSinCorreo';
import { boletaService } from '@/service/services/Boleta.service';

export const HomeView: React.FC<Props> = ({ }): JSX.Element => {

   const tokenSoftnet = readLocalStorage(KEY_SOFNET);
   const [filterFechas, setFilterFechas] = useState(2);
   const [error, setError] = useState('');
   const [isDetail, setIsDetail] = useState<boolean>(false);
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = useState(false);
   const [confirmModal, setConfirmModal] = useState(0);
   //1 = enviar 1, 2= enviar todos
   const [openModal, setOpenModal] = useState(false);
   const [boletaSelect, setBoletaSelect] = useState<any>();
   const [dataModal, setDataModal] = useState<any>()
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [email, setEmail] = useState<any>('');
   const [dataClients, setDataClients] = useState<any>();
   const [search, setSearch] = useState<any>('');
   const enviarBoletasMasivo = () => {
      console.log(dataClients)
   }

   useEffect(()=>{
      setOpen(true)
      getBoletasPendientes()
     // libroVentasDetalle()
      setOpen(false)
   },[])

   const getBoletasPendientes = async() => {
      const resp:any = await mailService.getBoletasPendientes();
      console.log(resp)
      setDataClients(resp?.data)
   }

   useEffect(()=>{

      if(confirmModal != 0){
         enviarCorreo(boletaSelect, confirmModal)
         setConfirmModal(0)
      }

   },[confirmModal])

   const enviarCorreo = async (data,confirm) =>{
      console.log('dnfkdsk')
      if(confirm == 1){
         const resp:any = await mailService.sendMail(data);
         if(resp?.data){
            setSnackBarConfig({...snackBarConfig, open:'true',severity:'success', message:'Correo enviado con éxito'})
       
         }else{
            setSnackBarConfig({...snackBarConfig, open:'true',severity:'warning', message:'Ocurrio un problema en el envio del correo'})
         }
      }
      if(confirm == 2){
         //aca van todos
         console.log(data)
         console.log(dataModal)
         const resp:any =  await mailService.sendMailCoincidentes(data,dataModal)
         if(resp?.data){
            setSnackBarConfig({...snackBarConfig, open:'true',severity:'success', message:'Correo enviado con éxito'})
       
         }else{
            setSnackBarConfig({...snackBarConfig, open:'true',severity:'warning', message:'Ocurrio un problema en el envio del correo'})
         }
      }
      getBoletasPendientes()
   }

   const validarCorreo = async(data) => {
      const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if(emailRegex.test(data?.correo)){
         
         const dataCoincidente = dataClients.filter((boleta) => { return boleta.rut_cliente == data?.rut_cliente})
         console.log(dataCoincidente)
         if(dataCoincidente.length > 1){
            console.log(dataCoincidente)
            setDataModal(dataCoincidente)
            setOpenModal(true)
         }else{
            enviarCorreo(data,1)
         }
      }else{
         setSnackBarConfig({...snackBarConfig, open:'true',severity:'warning', message:'El correo ingresado no es válido'})
      }
     
   }

   const handleInput = (e) => {
      const objChange = {
         ...email, [e.target.name]: e.target.value
      }
      setEmail(objChange);
      dataClients.map((value, i)=>{
         if(value.folio == e.target.name){
            return dataClients[i] = {...value, correo: e.target.value}
         }
      })
   }

   const searchMailPendientes = async (search) => {
      try{ const resp:any = await mailService.searchMailPendientes(search);
         setDataClients(resp?.data);

       }catch(e){
          console.log(e)
       }
    };
   const handleInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         console.log('dssks')
         switch (name) {
            case 'search':
               if(value.length>3)
               {
                  searchMailPendientes(value)
               }
               if(value.length==0)
               {
                  getBoletasPendientes();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };

   return (
      <Protected>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth='xl'>
            <Grid container justifyContent='space-between' spacing={3}>
               <Grid item xs={5} container alignItems="center">
                  <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }} >Envios de Boletas Pendientes</Typography>
               </Grid>
               <Grid item xs={7}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  spacing={3}
                  mt={3}
                  alignItems="center">
                  
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <InputLabel >Filtrar Resultados</InputLabel>
               <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="search"
                  value={search}
                  sx={{ bgcolor: '#E3F4FD' }}
                  onChange={handleInputSearch}
                  placeholder="Busqueda"
                  type="search"
                  id="password"
                  autoComplete="current-password"
                  size="small"
               />
            </Grid>
            <Divider
               sx={{ my: 5 }} />
            <Grid container spacing={1} >
               <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                     <TableHead sx={{ bgcolor: '#3f9bcc' }}>
                        <TableRow >
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '50px' }} >Folio</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Fecha</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >RUT Cliente</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '250px' }} align="left" >Razon Social</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Valor Neto</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Valor IVA</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Valor Total</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Email</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '200px' }} align="left" >Acciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {(dataClients || []).map((value, i) => {
                           return (

                              <TableRow
                                 key={i}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                              <>
                              <TableCell align="left" >{value.folio}</TableCell>
                                 <TableCell align="left" >{value.fecha}</TableCell>
                                 <TableCell align="left" >{value.rut_cliente}</TableCell>
                                 <TableCell align="left" >{value.razon_social}</TableCell>
                                 <TableCell align="left" >{moneyFormat(parseInt(value.valor_neto))}</TableCell>
                                 <TableCell align="left" >{moneyFormat(parseInt(value.valor_iva))}</TableCell>
                                 <TableCell align="left" >{moneyFormat(parseInt(value.valor_total))}</TableCell>
                                 {
                                    JSON.parse(value?.detalle_contactos).length > 1 ? (
                                       <FormControl variant="standard" fullWidth sx={{ mt: 1, minWidth: 100 }}>
                                          <Select
                                             name={value.folio}
                                             value={email[value.folio]?email[value.folio]:''}
                                             sx={{minWidth:'230px'}}
                                             onChange={handleInput}
                                             label="Age"
                                          >
                                             {JSON.parse(value?.detalle_contactos).map((item,i)=>{
                                                return(
                                                   <MenuItem value={item.email}>{item.email}</MenuItem>
                                                )
                                             })}
                                          </Select>
                                       </FormControl>
                                    ) : (
                                       <TableCell align="left" >
                                       <Input
                                       name={value.folio}
                                       placeholder='Ingrese su correo '
                                       sx={{minWidth:'230px'}}
                                       value={email[value.folio]?email[value.folio]:''}
                                       onChange={handleInput}
                                       /></TableCell>
                                    )
                                 }
                                 <TableCell align="left" >
                                    <Button variant='contained'
                                       sx={{
                                          background: '#004a8c', color: '#fff', '&:hover': {
                                             bgcolor: '#004a8c'
                                          }
                                       }}
                                       onClick={()=>{validarCorreo(value); setBoletaSelect(value)}}
                                       >Enviar</Button>
                                 </TableCell>
                              </>

                              </TableRow>
                           )
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
            {openModal && (
               <ModalClienteSinCorreo
               open = {openModal}
               setOpen = {setOpenModal}
               data = {dataModal}
               setData = {setDataModal}
               setConfirmModal = {setConfirmModal}
               />
            )}
         </Container>
      </Protected>
   )
};

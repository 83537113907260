import {
   ROUTE_HOME,
   ROUTE_SEETING,
   ROUTE_USERS,
   ROUTE_VIEWS_CREATE,
   ROUTE_VIEWS_FORMAT,
   ROUTE_VIEWS_TRACING,
   ROUTE_VIEWS_PEER_USER,
   ROUTE_VIEWS_PE_EVA,
   ROUTE_VIEWS_PE_USA,
   ROUTE_VIEWS_SUCESS,
   ROUTE_SEETING_REGISTER_PERIOD,
   ROUTE_USERS_REGISTER,
   ROUTE_USERS_UPDATE,
   ROUTE_ENVIADOS,
} from "../constants/route-map";

export const STATIC_ROUTERS = [
    {
      "name": "Evaluaciones",
      "icon": "EvaluateIcon",
      "moduloId": 1,
      "route": ROUTE_HOME,
      "isMenu": true,
      "route-navigators": [
         ROUTE_VIEWS_CREATE, ROUTE_VIEWS_FORMAT, ROUTE_VIEWS_TRACING
      ],
      "subMenu": [
        {
            "name": "Gestión de evaluación",
            "icon": "EvaluateIcon",
            "page": {
              "compare": null,
              "displayName": "Connect(Component)"
            },
            "isMenu": true,
            "route": ROUTE_HOME,
            "actions": {
              "actionInsert": true,
              "actionUpdate": true,
              "actionDelete": true,
              "actionSearch": true
            }
          }
      ]
    },
    {
      "name": "Evaluaciones",
      "icon": "EvaluateIcon",
      "moduloId": 2,
      "route": ROUTE_ENVIADOS,
      "isMenu": true,
    },
    {
      "name": "Usuarios",
      "icon": "UserIcon",
      "moduloId": 3,
      "route": ROUTE_USERS,
      "isMenu": true,
      "route-navigators": [
         ROUTE_USERS_REGISTER,
         ROUTE_USERS_UPDATE
      ],
      "subMenu": [
         {
            "name": "Usuarios",
            "icon": "UserIcon",
            "page": {
               "compare": null,
               "displayName": "Connect(Component)"
            },
            "isMenu": true,
            "route": ROUTE_USERS,
            "actions": {
               "actionInsert": true,
               "actionUpdate": true,
               "actionDelete": true,
               "actionSearch": true
            }
         }
      ]
    },
    {
      "name": "Configuración",
      "icon": "SettingIcon",
      "moduloId": 4,
      "route": ROUTE_SEETING,
      "isMenu": true,
      "route-navigators": [
         ROUTE_SEETING_REGISTER_PERIOD
      ],
      "subMenu": [
        {
          "name": "Configuración",
          "icon": "SettingIcon",
          "page": {
            "compare": null,
            "displayName": "Connect(Component)"
          },
          "isMenu": true,
          "route": ROUTE_SEETING,
          "actions": {
            "actionInsert": true,
            "actionUpdate": true,
            "actionDelete": true,
            "actionSearch": true
          }
        }
      ]
    },
    {
      "name": "Vistas Usuario",
      "icon": "ViewUserIcon",
      "moduloId": 5,
      "route": ROUTE_VIEWS_PEER_USER,
      "isMenu": true,
      "route-navigators": [
         ROUTE_VIEWS_SUCESS,
         ROUTE_VIEWS_PE_EVA,
         ROUTE_VIEWS_PE_USA
      ],
      "subMenu": [
         {
            "name": "Evaluación",
            "icon": "ViewUserIcon",
            "page": {
               "compare": null,
               "displayName": "Connect(Component)"
            },
            "isMenu": true,
            "route": ROUTE_VIEWS_PEER_USER,
            "actions": {
               "actionInsert": true,
               "actionUpdate": true,
               "actionDelete": true,
               "actionSearch": true
            }
         }
      ]
   },
]

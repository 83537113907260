import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Divider, FormControl, InputLabel, Select, MenuItem, InputAdornment, ListItem, Chip,Autocomplete, IconButton, Tooltip, Snackbar, Alert, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, FormHelperText, Input } from '@mui/material';
import { Box, spacing } from '@mui/system';
import './Modal.sass'
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import moment from 'moment'
import { isTemplateExpression } from 'typescript';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFNET } from '@/toolbox/constants/local-storage';
import { mailService } from '@/service/services/Mail.service';

type ModalProps = {
  open: boolean,
  setOpen: any,
  data:any,
  setData:any,
  setConfirmModal: any,
}

export const ModalClienteSinCorreo: React.FC<ModalProps> = (
  props: ModalProps
): JSX.Element => {

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'success',
        message: 'éxito',
        autoHideDuration: 3000,
    })

  const bodyModal = (
    <Box className='Modal' sx={{width:'25vw'}}>
      <Box >
        <Typography id="modal-modal-title" variant="h5" component="h1" textAlign='center' sx={{color:'#3F9BCC',mt:1, mb:3, fontWeight:'700', fontSize:'1.4em'}}>
          Se ha encontrado {props?.data?.length} boletas con el mismo rut, ¿Desea enviarlas al mismo correo?
        </Typography>
        <Grid container spacing={2} justifyContent='center'>
         <Button sx={{ mr:2, background: '#E4980D', color: '#fff', '&:hover': { bgcolor: '#E4980D'}}}
         onClick={()=>{props.setConfirmModal(1); props.setData(props.data); props.setOpen(false)}}>NO</Button>
        <Button sx={{ background: '#155172', color: '#fff', '&:hover': { bgcolor: '#155172'}}}
        onClick={()=>{props.setConfirmModal(2); props.setData(props.data); props.setOpen(false)}}>SI</Button>
        </Grid>
      </Box>
    </Box>
  )



  return (
    <div>
       <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
          severity={snackBarConfig.severity}
          variant="filled"
        >
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
      <Modal
        open={props.open}
        onClose={() => {
          props.setOpen(false)

        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {bodyModal}
      </Modal>
    </div>
  );
}

import React from 'react';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import AllRoutes from './routes/AllRoutes';

import { authenticationService } from './service/services/Authentication.service';
import { useLocalStorage } from '@toolbox/hooks/local-storage.hook';
import { KEY_USER_DATA } from '@toolbox/constants/local-storage';

function App() {
   // const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);
   // useEffect(() => {
   //    authenticationService.currentUser.subscribe();
   //    if(dataUser){
   //       authenticationService.updateRoutes(dataUser.token, dataUser.user);
   //    }
   // },// eslint-disable-next-line
   // []);

   return (
      <Provider store = {store}>
         <AllRoutes/>
      </Provider>
   );
}

export default App;

import React, { useEffect, useRef, useState } from 'react';

import { Protected } from '@components/layout/Protected';
import { Props } from './CorreosEnviados.type';
import { authenticationService } from '@service/services/Authentication.service';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Button, CircularProgress, Container, Divider, InputLabel,FormControl, FormHelperText, Grid, Input, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { SpinnerGrow } from '@/components/common/Spinner';
import Paper from '@mui/material/Paper';
import { mailService } from '@/service/services/Mail.service';
import { set } from 'react-hook-form';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import { dateFormatTime, dateFormat } from '@/toolbox/helpers/date.helper';
import moment from 'moment'
import { isTemplateExpression } from 'typescript';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_SOFNET, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ModalEnviarCopia } from './Modal/ModalEnviarCopia';

export const CorreosEnviadosView: React.FC<Props> = ({ }): JSX.Element => {

   const tokenSoftnet = readLocalStorage(KEY_SOFNET);
   const datoUsuario = readLocalStorage(KEY_USER_DATA);
     const [error, setError] = useState('');
   const [isDetail, setIsDetail] = useState<boolean>(false);
   const [loading, setLoading] = useState(false);
   const [open, setOpen] = useState(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'success',
      message: 'Error',
      autoHideDuration: 5000,
   })
   const [dataClients, setDataClients] = useState<any>();
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [clienteCopia, setClienteCopia] = useState<any>();
   const [search, setSearch] = useState<any>('');

   useEffect(()=>{
      setOpen(true)
      console.log(readLocalStorage(KEY_USER_DATA))
      boletasEnviadas();
     // libroVentasDetalle()
      setOpen(false)
   },[])

   const boletasEnviadas = async() => {
      const resp:any = await mailService.getBoletasEnviadas(datoUsuario?.user?.id);
      console.log(resp)
      setDataClients(resp?.data)
   }
   
    const ReenviarCorreo = async(boleta) => {
         const resp:any = await mailService.postReenviarCorreo(boleta, datoUsuario?.user?.id)
         if(resp?.status?.code == 200){
            setSnackBarConfig({...snackBarConfig,open:true, severity: 'success',message: 'Correo Reenvidado con éxito',})
         }else{
            setSnackBarConfig({...snackBarConfig,open:true,message: 'Ups! ha ocurrido un problema',})
         }
    }

    const searchMailEnviados = async (search) => {
      try{ const resp:any = await mailService.searchMailEnviados(search);
         setDataClients(resp?.data);

       }catch(e){
          console.log(e)
       }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      try{   const name = event.target.name;
         const value = event.target.value;
         switch (name) {
            case 'search':
               if(value.length>3)
               {
                  searchMailEnviados(value)
               }
               if(value.length==0)
               {
                  boletasEnviadas();
               }
               setSearch(value);

               break;
            default:
               break;
         }
      }catch(e){
         console.log(e)
      }
      };


   return (
      <Protected>
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>Cargando...</strong></h1>
            </Grid>
         </Backdrop>
         <Container maxWidth='xl'>
            <Grid container justifyContent='space-between' spacing={3}>
               <Grid item xs={5} container alignItems="center">
                  <Typography sx={{ color: '#155172', fontWeight: '700', fontSize: '2.8em' }} >Envios de Boletas Realizados</Typography>
               </Grid>
            </Grid>

            <Grid item xs={12}>
               <InputLabel >Filtrar Resultados</InputLabel>
               <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="search"
                  value={search}
                  sx={{ bgcolor: '#E3F4FD' }}
                  onChange={handleInput}
                  placeholder="Busqueda"
                  type="search"
                  id="password"
                  autoComplete="current-password"
                  size="small"
               />
            </Grid>
            <Divider
               sx={{ my: 5 }} />
            <Grid container spacing={1} >
               {/* <Button onClick={() => { libroVentasDetallePorFecha() }}>ConsultarPorFecha</Button>
               <Button onClick={() => { libroVentasDetalle() }}>Consultar</Button> */}
                  {/* <Grid item container
                     direction="row"
                     justifyContent="center"
                     alignItems="center" spacing={2} sx={{ mb: 3 }}>
                     <Grid item sx={{ mt: 1 }}>
                        <Grid >
                           Filtrar Fecha por:
                        </Grid>
                     </Grid>
                  </Grid> */}
               <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                     <TableHead sx={{ bgcolor: '#3f9bcc' }}>
                        <TableRow >
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '50px' }} >Folio</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Fecha Emisión</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Fecha Envio</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >RUT Cliente</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '250px' }} align="left" >Razon Social</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Valor Neto</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Valor IVA</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Valor Total</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '100px' }} align="left" >Email</TableCell>
                           <TableCell sx={{ color: 'white', fontSize: '1.1em', width: '200px' }} align="left" >Acciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {(dataClients || []).map((value, i) => {
                           return (
                              
                              <TableRow
                                 key={i}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                              <>
                              <TableCell align="left" >{value.folio}</TableCell>
                                 <TableCell align="left" >{value.fecha}</TableCell>
                                 <TableCell align="left" >{dateFormat(value.updated_at) +' '+ dateFormatTime(value.updated_at)}</TableCell>
                                 <TableCell align="left" >{value.rut_cliente}</TableCell>
                                 <TableCell align="left" >{value.razon_social}</TableCell>
                                 <TableCell align="left" >{moneyFormat(parseInt(value.valor_neto))}</TableCell>
                                 <TableCell align="left" >{moneyFormat(parseInt(value.valor_iva))}</TableCell>
                                 <TableCell align="left" >{moneyFormat(parseInt(value.valor_total))}</TableCell>
                                 <TableCell align="left" >{value?.correo}</TableCell>
                                 <TableCell align="center" >
                                    <Button variant='contained'
                                       sx={{
                                          background: '#155172', color: '#fff', '&:hover': {
                                             bgcolor: '#155172'
                                          }
                                       }}
                                       onClick={()=>{
                                          ReenviarCorreo(value)
                                        }}
                                       >Reenviar</Button>
                                    <Button variant='contained'
                                       sx={{ mt:2,
                                          background: '#E4980D', color: '#fff', '&:hover': {
                                             bgcolor: '#E4980D'
                                          }
                                       }}
                                       onClick={()=>{
                                          setOpenModal(true)
                                          setClienteCopia({...value,correo:''})
                                        }}
                                       >Enviar Copia</Button>
                                 </TableCell>
                              </>
                                 
                              </TableRow>
                           )
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Grid>
            {openModal && 
            <ModalEnviarCopia
            open={openModal}
            setOpen={setOpenModal}
            data={clienteCopia}
            setData={setClienteCopia}
            funcion={ReenviarCorreo}
            />}
         </Container>
      </Protected>
   )
};

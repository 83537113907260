import {
   ROUTE_HOME,
   ROUTE_SEETING,
   ROUTE_USERS,
   ROUTE_VIEWS_CREATE,
   ROUTE_VIEWS_FORMAT,
   ROUTE_VIEWS_PEER_USER,
   ROUTE_VIEWS_PE_EVA,
   ROUTE_VIEWS_PE_USA,
   ROUTE_VIEWS_SUCESS,
   ROUTE_VIEWS_TRACING,
   ROUTE_SEETING_REGISTER_PERIOD,
   ROUTE_REPORTS,
   ROUTE_USERS_UPDATE,
   ROUTE_USERS_REGISTER,
   ROUTE_ENVIADOS,
} from "../constants/route-map";

export const ROLE_SUPER_ADMIN = 'Superadministrador';
export const ROLE_ADMIN = 'ADMINISTRADOR';
export const ROLE_USER  = 'Usuario';

export const ROLE_ADMIN_WITH_USER  = 'AdministradorUsuario';

const rolesforDevs = ()=> {
   const privilegies = (process.env.REACT_APP_MODE||'').toLocaleUpperCase();
   if (privilegies === 'DEV' ||
      privilegies === 'PRE')
   return [
      'villanuevagiovanna57@gmail.com'
   ]
   else return []
}

export const STATIC_SUPER_ADMIN = [
   'villanuevagiovanna57@gmail.com',
   ...rolesforDevs()
]
export const STATIC_ADMIN = [
'omar.villanueva@2myr.com',
   'villanuevagiovanna57@gmail.com',
]

export const ROUTES_FOR_SUPER_ADMIN = [
   {
      module: ROUTE_HOME,
      navigators: [ROUTE_VIEWS_CREATE, ROUTE_VIEWS_FORMAT, ROUTE_VIEWS_TRACING]
   },
   {
      module: ROUTE_USERS,
      navigators: [ROUTE_USERS_UPDATE, ROUTE_USERS_REGISTER]
   },
   {
      module: ROUTE_SEETING,
      navigators: [ROUTE_SEETING_REGISTER_PERIOD]
   },
   {
      module: ROUTE_VIEWS_PEER_USER,
      navigators: [ROUTE_VIEWS_SUCESS, ROUTE_VIEWS_PE_EVA, ROUTE_VIEWS_PE_USA]
   },
   {
      module: ROUTE_REPORTS,
      navigators: []
   },
]
export const ROUTES_FOR_ADMIN = [
   {
      module: ROUTE_HOME,
      navigators: [ROUTE_VIEWS_CREATE, ROUTE_VIEWS_FORMAT, ROUTE_VIEWS_TRACING]
   },
   {
      module: ROUTE_USERS,
      navigators: [ROUTE_USERS_UPDATE, ROUTE_USERS_REGISTER]
   },
   {
      module: ROUTE_ENVIADOS,
      navigators: [ROUTE_USERS_UPDATE, ROUTE_USERS_REGISTER]
   },
   {
      module: ROUTE_SEETING,
      navigators: [ROUTE_SEETING_REGISTER_PERIOD]
   },
      {
      module: ROUTE_REPORTS,
      navigators: []
   },
]
export const ROUTES_FOR_USER = [
   {
      module: ROUTE_VIEWS_PEER_USER,
      navigators: [ROUTE_VIEWS_SUCESS, ROUTE_VIEWS_PE_EVA, ROUTE_VIEWS_PE_USA]
   },
]

export const MAIN_REDIRECT_FOR_SUPER_ADMIN = ROUTE_HOME;
export const MAIN_REDIRECT_FOR_ADMIN       = ROUTE_HOME;
export const MAIN_REDIRECT_FOR_USER        = ROUTE_VIEWS_PEER_USER;
